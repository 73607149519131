/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Offer API
 * OpenAPI spec version: latest
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import useCustomInstance from '../fetchers/useCustomInstance';
export type PostOfferIdSeatsParams = {
/**
 * Currency of display
 */
curr: string;
};

export type GetOfferIdSeatmapLegIdParams = {
/**
 * Currency of display
 */
curr: string;
};

export type GetSummaryParams = {
/**
 * Id of order to get summary for
 */
order_id: string;
/**
 * Last name from the the order to get summary for.
 */
last_name?: string;
/**
 * Currency of display
 */
curr: string;
};

export type GetDeeplinkChargeBreakdownParams = {
/**
 * example: 21.99-EUR-1336--27.95-CHF-1336
 */
fares: string;
/**
 * Dohop connection fee
 */
fee?: string;
/**
 * Currency of display
 */
curr: string;
};

export type GetItineraryParams = {
lang?: string;
out: string;
home?: string;
combo: string;
};

export type PostMbidChallengeCallbackParams = {
/**
 * Challenge id (provider transaction id)
 */
challenge_id?: number;
};

export type PostOfferIdBookParams = {
/**
 * Currency of display
 */
curr: string;
};

export type GetOfferIdChargesParams = {
/**
 * Currency of display
 */
curr: string;
};

export type GetOfferIdPollParams = {
/**
 * Currency of display
 */
curr: string;
};

export type PostOfferIdPassengersParams = {
/**
 * Currency of display
 */
curr: string;
};

export type PatchOfferIdServicesParams = {
/**
 * Currency of display
 */
curr: string;
};

export type PatchOfferIdBundlesParams = {
/**
 * Id of bundle to select
 */
bundle_id: string;
/**
 * Currency of display
 */
curr: string;
};

export type GetOfferIdParams = {
/**
 * Currency of display
 */
curr: string;
};

export type PostNewParams = {
/**
 * Residency
 */
res: string;
/**
 * Currency of display
 */
curr: string;
/**
 * Language
 */
lang?: string;
get_fare_bundles?: boolean;
/**
 * Partner name
 */
mbs_partner: string;
/**
 * example: 0-1
 */
combo: string;
/**
 * example: 21.99-EUR-1336--27.95-CHF-1336
 */
fares: string;
/**
 * Outbound itineraries, example: BCNGVA202111191445016250140U21408---GVAAMS202111191845020300145U21357
 */
out: string;
/**
 * Homebound itineraries
 */
home?: string;
/**
 * Dohop connection fee
 */
fee?: string;
total_fee?: string;
/**
 * n_adults or passenger_ages must be set, but they can not be used together
 */
n_adults?: number;
/**
 * Can not be used with n_adults or youngster_ages
 */
passenger_ages?: string;
/**
 * Can only be used with n_adults, not with passenger_ages
 */
youngsters_ages?: string;
utm_source?: string;
utm_content?: string;
utm_medium?: string;
utm_campaign?: string;
'ticketing-partner'?: string;
tracking_id?: string;
issued_at?: string;
/**
 * deeplink signature parameter
 */
sig?: string;
};

export type GetNewParams = {
/**
 * Residency
 */
res: string;
/**
 * Currency of display
 */
curr: string;
/**
 * Language
 */
lang?: string;
get_fare_bundles?: boolean;
/**
 * Partner name
 */
mbs_partner: string;
/**
 * example: 0-1
 */
combo: string;
/**
 * example: 21.99-EUR-1336--27.95-CHF-1336
 */
fares: string;
/**
 * Outbound itineraries, example: BCNGVA202111191445016250140U21408---GVAAMS202111191845020300145U21357
 */
out: string;
/**
 * Homebound itineraries
 */
home?: string;
/**
 * Dohop connection fee
 */
fee?: string;
total_fee?: string;
/**
 * n_adults or passenger_ages must be set, but they can not be used together
 */
n_adults?: number;
/**
 * Can not be used with n_adults or youngster_ages
 */
passenger_ages?: string;
/**
 * Can only be used with n_adults, not with passenger_ages
 */
youngsters_ages?: string;
utm_source?: string;
utm_content?: string;
utm_medium?: string;
utm_campaign?: string;
'ticketing-partner'?: string;
tracking_id?: string;
issued_at?: string;
/**
 * deeplink signature parameter
 */
sig?: string;
};

export type ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode = typeof ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode[keyof typeof ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode = {
  NUMBER_402: 402,
} as const;

/**
 * Something went wrong during fetching card info
 */
export interface ServerPersesModelsErrorResponseErrorPaymentError {
  description?: string;
  error_code: ErrorCode;
  recoverable?: boolean;
  response_code?: ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode;
  status_text: StatusText;
}

export interface ServerPersesAppsOfferV7SchemasPollingRefreshMismatchError {
  error_code: HierarchicalErrorCode;
  is_recoverable: boolean;
  initial_price: Price;
  new_price: Price;
}

export interface ServerPersesAppsOfferV7SchemasPollingPaymentError {
  error_code: HierarchicalErrorCode;
  is_recoverable: boolean;
  user_information?: string;
}

export interface ServerPersesAppsOfferV7SchemasOfferRefreshMismatchError {
  initial_price: Price;
  new_price: Price;
}

export type UpdateOfferPassengerFrequentFlyerNumbers = {[key: string]: string};

export interface UpdateOfferPassenger {
  date_of_birth?: string;
  first_name?: string;
  gender?: PaxGender;
  last_name?: string;
  passenger_id: string;
  passport_country?: string;
  passport_expiration?: string;
  passport_number?: string;
  title?: string;
  traveling_with?: string;
  frequent_flyer_numbers?: UpdateOfferPassengerFrequentFlyerNumbers;
}

export interface UpdatePassengersRequest {
  passengers: UpdateOfferPassenger[];
}

export type TravelBookingBookingType = typeof TravelBookingBookingType[keyof typeof TravelBookingBookingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TravelBookingBookingType = {
  travel: 'travel',
} as const;

export interface TravelBooking {
  booking_type?: TravelBookingBookingType;
  booking_id: string;
  fare_code: string;
  legs: Leg[];
  pnr?: string;
  status: PollingBookingStatus;
  total: Price;
}

/**
 * An enumeration.
 */
export type TransportType = typeof TransportType[keyof typeof TransportType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransportType = {
  AIRPORT: 'AIRPORT',
  AIRPORT_AND_TRAIN: 'AIRPORT_AND_TRAIN',
  TRAIN: 'TRAIN',
} as const;

export interface TokenizedCardDetails {
  pci_proxy_card_token: string;
  pci_proxy_cvc_token: string;
  masked_card: string;
}

export interface SwallowedRefreshMismatchError {
  swallowed_amount: Price;
  hidden_mismatch: boolean;
}

export type SummaryOtherServicesItem = BookedAdditionalLoungeAccess | DohopServiceBase | AdditionalLuggageGroundHandlingBase | AdditionalServiceBase;

export interface Summary {
  leg_summaries: LegGroupSummary[];
  other_services: SummaryOtherServicesItem[];
  total: Price;
}

/**
 * An enumeration.
 */
export type StatusText = typeof StatusText[keyof typeof StatusText];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusText = {
  Incomplete: 'Incomplete',
  Complete: 'Complete',
  NotProcessed: 'NotProcessed',
  Unknown: 'Unknown',
} as const;

export type ServiceGroupServicesItem = AdditionalLuggage | SelectedSeat;

export interface ServiceGroup {
  iatas: string[];
  carrier_codes: string[];
  legs: Leg[];
  passenger_id: string;
  services: ServiceGroupServicesItem[];
}

/**
 * An enumeration.
 */
export type ServiceClass = typeof ServiceClass[keyof typeof ServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceClass = {
  alyzia: 'alyzia',
  bag_drop: 'bag_drop',
  bistro_voucher: 'bistro_voucher',
  checked_bags_for_fee: 'checked_bags_for_fee',
  check_in_at_counters: 'check_in_at_counters',
  comfort_packs: 'comfort_packs',
  comfort_packs_priority_service: 'comfort_packs_priority_service',
  discount_baggage_discount: 'discount_baggage_discount',
  discount_child_ticket_discount: 'discount_child_ticket_discount',
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
  exclusive_cabin: 'exclusive_cabin',
  flight_cancellation_mismatch: 'flight_cancellation_mismatch',
  flight_cancel_with_fee: 'flight_cancel_with_fee',
  flight_changes_with_fee: 'flight_changes_with_fee',
  flight_change_mismatch: 'flight_change_mismatch',
  free_flight_cancel: 'free_flight_cancel',
  free_flight_changes: 'free_flight_changes',
  free_route_changes: 'free_route_changes',
  free_ticket_changes: 'free_ticket_changes',
  itinerary_change: 'itinerary_change',
  life_miles: 'life_miles',
  lounge_access: 'lounge_access',
  loyalty_programs: 'loyalty_programs',
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  luggage_personal: 'luggage_personal',
  meals: 'meals',
  meals_snack: 'meals_snack',
  meals_standard: 'meals_standard',
  priority_check_in: 'priority_check_in',
  refunds: 'refunds',
  seat_selection: 'seat_selection',
  seat_selection_any: 'seat_selection_any',
  seat_selection_economy: 'seat_selection_economy',
  seat_selection_emergency_exit: 'seat_selection_emergency_exit',
  seat_selection_extra_legroom: 'seat_selection_extra_legroom',
  seat_selection_first_row: 'seat_selection_first_row',
  seat_selection_flatbed: 'seat_selection_flatbed',
  seat_selection_mismatch: 'seat_selection_mismatch',
  seat_selection_plus: 'seat_selection_plus',
  seat_selection_standard: 'seat_selection_standard',
  seat_selection_upfront: 'seat_selection_upfront',
  seat_selection_standard_upfront: 'seat_selection_standard_upfront',
  speedy_boarding: 'speedy_boarding',
  ticket_changes: 'ticket_changes',
  train_ticket: 'train_ticket',
  vip_lounge: 'vip_lounge',
  vip_lounge_and_meal: 'vip_lounge_and_meal',
  children_travel_free: 'children_travel_free',
  city_ticket_included: 'city_ticket_included',
  free_cancellation: 'free_cancellation',
  not_train_specific: 'not_train_specific',
  seat_reservation: 'seat_reservation',
  train_specific_travel: 'train_specific_travel',
  unspecified_default: 'unspecified_default',
} as const;

export type ServiceBookingBookingType = typeof ServiceBookingBookingType[keyof typeof ServiceBookingBookingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceBookingBookingType = {
  service: 'service',
  commission: 'commission',
} as const;

export interface ServiceBooking {
  booking_type?: ServiceBookingBookingType;
  booking_id: string;
  total: Price;
  status: PollingBookingStatus;
}

export type SelectedSeatPrice = PriceWithBreakdown | Price;

export type SelectedSeatServiceClass = typeof SelectedSeatServiceClass[keyof typeof SelectedSeatServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectedSeatServiceClass = {
  seat_selection: 'seat_selection',
} as const;

export interface SelectedSeat {
  can_increment: boolean;
  can_decrement: boolean;
  service_id: string;
  service_class: SelectedSeatServiceClass;
  quantity: number;
  price: SelectedSeatPrice;
  col: string;
  leg_id: string;
  passenger_id: string;
  row: number;
  seat_offer_id: string;
}

export interface SelectServiceRequest {
  service_id: string;
  quantity: number;
}

export interface SelectSeatRequest {
  leg_id: string;
  seat_offer_id?: string;
  passenger_id: string;
}

/**
 * An enumeration.
 */
export type SeatSectionAttribute = typeof SeatSectionAttribute[keyof typeof SeatSectionAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatSectionAttribute = {
  temp: 'temp',
} as const;

/**
 * An enumeration.
 */
export type SeatRowAttribute = typeof SeatRowAttribute[keyof typeof SeatRowAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatRowAttribute = {
  exit_row: 'exit_row',
  extra_leg_room: 'extra_leg_room',
  middle_row: 'middle_row',
  wing_row: 'wing_row',
} as const;

export interface SeatMapSeatOffer {
  pax_id: string;
  price: Price;
  seat_offer_id: string;
}

export interface SeatMapSeat {
  attributes: SeatAttribute[];
  col: string;
  offers: SeatMapSeatOffer[];
  row: number;
  seat_type: string;
  width_numerator: number;
}

export interface SeatMapRow {
  attributes: SeatRowAttribute[];
  items: SeatMapRowItemsItem[];
}

export interface SeatMapSection {
  attributes: SeatSectionAttribute[];
  rows: SeatMapRow[];
  name: string;
}

/**
 * An enumeration.
 */
export type SeatMapItemType = typeof SeatMapItemType[keyof typeof SeatMapItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatMapItemType = {
  display: 'display',
  empty: 'empty',
  exit: 'exit',
  letter: 'letter',
  seat: 'seat',
} as const;

export interface SeatMapDisplayItem {
  item_type: SeatMapItemType;
  width_numerator: number;
  text: string;
}

export type SeatMapRowItemsItem = SeatMapDisplayItem | SeatMapSeat;

/**
 * An enumeration.
 */
export type SeatAttribute = typeof SeatAttribute[keyof typeof SeatAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatAttribute = {
  aisle: 'aisle',
  exit: 'exit',
  extra_leg_room: 'extra_leg_room',
  middle: 'middle',
  window: 'window',
  wing_seat: 'wing_seat',
} as const;

export interface RetryPaymentInfo {
  browser_info: BrowserInfo;
  card?: CardInfo;
  redirect_info?: RedirectInfo;
}

export interface RetryBookOfferRequest {
  passengers?: UpdateOfferPassenger[];
  billing_address?: BillingAddress;
  contact_information?: ContactInformationRequest;
  payment_info: RetryPaymentInfo;
}

export interface ResponseError {
  status_text: StatusText;
  error_code: string;
  response_code: number;
  description?: string;
  recoverable?: boolean;
}

export interface RedirectInfo {
  three_ds1_redirect_url: string;
}

export type ReceivedResponseResponse = typeof ReceivedResponseResponse[keyof typeof ReceivedResponseResponse];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReceivedResponseResponse = {
  Received: 'Received',
} as const;

/**
 * "Okey" response
 */
export interface ReceivedResponse {
  response?: ReceivedResponseResponse;
}

export interface PriceWithPriceCode {
  currency: string;
  amount: number;
  price_code: string;
}

export interface PriceWithBreakdown {
  currency: string;
  amount: number;
  breakdown: PriceWithPriceCode[];
}

export interface Price {
  currency: string;
  amount: number;
}

/**
 * An enumeration.
 */
export type PollingBookingStatus = typeof PollingBookingStatus[keyof typeof PollingBookingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PollingBookingStatus = {
  booking_in_progress: 'booking_in_progress',
  cancelled: 'cancelled',
  failed: 'failed',
  price_check_in_progress: 'price_check_in_progress',
  pending: 'pending',
  success: 'success',
} as const;

export type PollResponseBookingsItem = TravelBooking | ServiceBooking;

export interface PaymentInfo {
  browser_info: BrowserInfo;
  card: CardInfo;
  redirect_info?: RedirectInfo;
}

/**
 * An enumeration.
 */
export type PaxType = typeof PaxType[keyof typeof PaxType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaxType = {
  a: 'a',
  c: 'c',
  i: 'i',
} as const;

/**
 * An enumeration.
 */
export type PaxGender = typeof PaxGender[keyof typeof PaxGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaxGender = {
  m: 'm',
  f: 'f',
  x: 'x',
} as const;

export type PassengerFrequentFlyerNumbers = {[key: string]: string};

export interface Passenger {
  date_of_birth?: string;
  first_name?: string;
  gender?: PaxGender;
  last_name?: string;
  passenger_id: string;
  passport_country?: string;
  passport_expiration?: string;
  passport_number?: string;
  title?: string;
  traveling_with?: string;
  frequent_flyer_numbers?: PassengerFrequentFlyerNumbers;
  expected_type: PaxType;
}

export interface PassengerError {
  error_code: HierarchicalErrorCode;
  is_recoverable: boolean;
  passengers: Passenger[];
}

export type PollResponseError = Error | PassengerError | ServerPersesAppsOfferV7SchemasPollingRefreshMismatchError | BillingAddressError | ServerPersesAppsOfferV7SchemasPollingPaymentError;

export interface PollResponse {
  bookings: PollResponseBookingsItem[];
  challenge?: ChallengeInfo;
  error?: PollResponseError;
  keep_polling: boolean;
  total: Price;
}

export type OrderSummaryResponseBookingsItem = TravelBooking | ServiceBooking;

export interface OrderSummaryResponse {
  billing_address: BillingAddress;
  bookings: OrderSummaryResponseBookingsItem[];
  contact_information: ContactInformation;
  itinerary: ItineraryWithLegCombo;
  passengers: Passenger[];
  secret: string;
  summary: Summary;
}

export interface OfferSeatMap {
  leg_id: string;
  sections: SeatMapSection[];
}

export type OfferResponseErrorsItem = ServerPersesAppsOfferV7SchemasOfferRefreshMismatchError | SwallowedRefreshMismatchError;

export type OfferResponseChargeTransactionsItem = CarrierChargeTransaction | DohopChargeTransaction;

export type OfferResponseAllPaxServicesItem = AdditionalLoungeAccess | AdditionalLuggageGroundHandling | DohopService;

/**
 * An enumeration.
 */
export type OfferRequirement = typeof OfferRequirement[keyof typeof OfferRequirement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferRequirement = {
  passport_required: 'passport_required',
  passport_country_required: 'passport_country_required',
} as const;

export interface OfferResponse {
  all_pax_services: OfferResponseAllPaxServicesItem[];
  billing_address: BillingAddress;
  bundle_groups: BundleGroup[];
  charge_transactions: OfferResponseChargeTransactionsItem[];
  contact_information: ContactInformation;
  errors: OfferResponseErrorsItem[];
  offer_id: string;
  passengers: Passenger[];
  requirements: OfferRequirement[];
  secret: string;
  service_groups: ServiceGroup[];
  summary: Summary;
}

export interface OfferPassengersResponse {
  passengers: Passenger[];
}

export interface OfferBundle {
  bundle_id: string;
  bundle_codes: string[];
  is_selected?: boolean;
  price: Price;
  services: OfferBundleServicesItem[];
}

/**
 * Any JSON
 */
export interface NotStructured { [key: string]: any }

export interface NotFoundResponseError {
  status_text: StatusText;
  error_code: string;
  response_code: number;
  description?: string;
  recoverable?: boolean;
}

export type LegGroupSummaryAdditionalServicesItem = AdditionalLuggage | SelectedSeat;

export interface Leg {
  origin: AirportInfo;
  destination: AirportInfo;
  departure: string;
  arrival: string;
  marketing_carrier: Carrier;
  operating_carrier: Carrier;
  flight_number: string;
  duration?: string;
  is_outbound: boolean;
  leg_id: string;
}

export interface LegGroupSummary {
  additional_services: LegGroupSummaryAdditionalServicesItem[];
  bundle: BundleSummary;
  included_services: LegGroupSummaryIncludedServicesItem[];
  legs: Leg[];
}

export interface ItineraryWithLegCombo {
  outbound: Leg[];
  homebound: Leg[];
  itinerary_combo: string;
}

export interface IncludedNameOnlyService {
  quantity: number;
  service_class: ServiceClass;
}

export type IncludedLuggageServiceClass = typeof IncludedLuggageServiceClass[keyof typeof IncludedLuggageServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncludedLuggageServiceClass = {
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  luggage_personal: 'luggage_personal',
} as const;

export interface IncludedLuggage {
  quantity: number;
  service_class: IncludedLuggageServiceClass;
  dimensions?: Dimensions;
  weight?: number;
}

export type IncludedLifeMilesServiceClass = typeof IncludedLifeMilesServiceClass[keyof typeof IncludedLifeMilesServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncludedLifeMilesServiceClass = {
  life_miles: 'life_miles',
} as const;

export interface IncludedLifeMiles {
  quantity: number;
  service_class: IncludedLifeMilesServiceClass;
  value: number;
}

export type IncludedComfortPacksServiceClass = typeof IncludedComfortPacksServiceClass[keyof typeof IncludedComfortPacksServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncludedComfortPacksServiceClass = {
  comfort_packs: 'comfort_packs',
} as const;

export interface IncludedComfortPacks {
  quantity: number;
  service_class: IncludedComfortPacksServiceClass;
  comfort_pack_name: string;
}

export type OfferBundleServicesItem = IncludedComfortPacks | IncludedLifeMiles | IncludedLuggage | IncludedNameOnlyService;

export type LegGroupSummaryIncludedServicesItem = IncludedComfortPacks | IncludedLifeMiles | IncludedLuggage | IncludedNameOnlyService;

/**
 * Please note that the values of this enumeration may change. Forward compatibility is expected
 */
export type HierarchicalErrorCode = typeof HierarchicalErrorCode[keyof typeof HierarchicalErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HierarchicalErrorCode = {
  system_error: 'system_error',
  system_errorauthentication_error: 'system_error.authentication_error',
  system_errorbooking_not_found: 'system_error.booking_not_found',
  system_errorbooking_server_unreachable: 'system_error.booking_server_unreachable',
  system_errorbotrun_failed: 'system_error.botrun_failed',
  system_errorbotrun_killed: 'system_error.botrun_killed',
  system_errorbotrun_timeout: 'system_error.botrun_timeout',
  system_errorconcurrent_booking: 'system_error.concurrent_booking',
  system_errorflight_not_in_index: 'system_error.flight_not_in_index',
  system_errorinvalid_leg_string: 'system_error.invalid_leg_string',
  system_errorlock_not_acquired: 'system_error.lock_not_acquired',
  system_errornot_enough_details: 'system_error.not_enough_details',
  system_errorprotocol_error: 'system_error.protocol_error',
  system_errorpsp_acquring_error: 'system_error.psp_acquring_error',
  payment_errorpsp_error: 'payment_error.psp_error',
  system_errorpsp_unknown_error: 'system_error.psp_unknown_error',
  system_errorpsp_unmappable_error: 'system_error.psp_unmappable_error',
  system_errorrefund_not_received: 'system_error.refund_not_received',
  system_errorrequest_limit_reached: 'system_error.request_limit_reached',
  system_errorvendor_not_found: 'system_error.vendor_not_found',
  system_errorvendor_protocol_error: 'system_error.vendor_protocol_error',
  availability_error: 'availability_error',
  availability_errorfare_not_found: 'availability_error.fare_not_found',
  availability_errorfare_sold_out: 'availability_error.fare_sold_out',
  availability_errorseat_not_available: 'availability_error.seat_not_available',
  availability_errorbundle_not_available: 'availability_error.bundle_not_available',
  booking_error: 'booking_error',
  booking_errordeparture_too_close: 'booking_error.departure_too_close',
  booking_errorduplicate_booking: 'booking_error.duplicate_booking',
  booking_errorfare_mismatch: 'booking_error.fare_mismatch',
  booking_errorillegal_pax_group: 'booking_error.illegal_pax_group',
  booking_errorschedule_mismatch: 'booking_error.schedule_mismatch',
  booking_errorsession_timeout: 'booking_error.session_timeout',
  booking_errorunsuccessful: 'booking_error.unsuccessful',
  booking_errorvendor_system_error: 'booking_error.vendor_system_error',
  booking_errorvirtual_card_not_funded: 'booking_error.virtual_card_not_funded',
  payment_error: 'payment_error',
  payment_errorcard_declined: 'payment_error.card_declined',
  payment_errorcard_declinedcard_not_authorised: 'payment_error.card_declined.card_not_authorised',
  payment_errorpayment_authentication_error: 'payment_error.payment_authentication_error',
  payment_errorcard_declinedfraud_booking: 'payment_error.card_declined.fraud_booking',
  payment_errorcard_declinedfraud_bookingflagged_by_internal_fraud_check: 'payment_error.card_declined.fraud_booking.flagged_by_internal_fraud_check',
  payment_errorcard_declinednot_enough_funds: 'payment_error.card_declined.not_enough_funds',
  payment_errorcard_expired: 'payment_error.card_expired',
  payment_errorcard_is_blocked: 'payment_error.card_is_blocked',
  payment_errorcard_issuer_unavailable: 'payment_error.card_issuer_unavailable',
  payment_errorcard_verification_expired: 'payment_error.card_verification_expired',
  payment_errorcard_verification_failed: 'payment_error.card_verification_failed',
  payment_errorcould_not_get_tokenized_card_from_payment_proxy: 'payment_error.could_not_get_tokenized_card_from_payment_proxy',
  payment_errorinvalid_cvc: 'payment_error.invalid_cvc',
  payment_errorinvalid_card_number: 'payment_error.invalid_card_number',
  payment_errorinvalid_card_type: 'payment_error.invalid_card_type',
  payment_errorinvalid_email: 'payment_error.invalid_email',
  payment_errorinvalid_expiration: 'payment_error.invalid_expiration',
  payment_errorinvalid_name_on_card: 'payment_error.invalid_name_on_card',
  payment_errortransaction_cancelled: 'payment_error.transaction_cancelled',
  payment_errornone_supported_3ds1: 'payment_error.none_supported_3ds1',
  shipping_info_error: 'shipping_info_error',
  shipping_info_errorinvalid_address: 'shipping_info_error.invalid_address',
  shipping_info_errorinvalid_city_name: 'shipping_info_error.invalid_city_name',
  shipping_info_errorinvalid_country_code: 'shipping_info_error.invalid_country_code',
  shipping_info_errorinvalid_postal_code: 'shipping_info_error.invalid_postal_code',
  shipping_info_errorinvalid_province: 'shipping_info_error.invalid_province',
  shipping_info_errorinvalid_phone: 'shipping_info_error.invalid_phone',
  validation_error: 'validation_error',
  validation_errorinvalid_fare_data: 'validation_error.invalid_fare_data',
  validation_errorinvalid_leg_data: 'validation_error.invalid_leg_data',
  validation_errormissing_argument: 'validation_error.missing_argument',
  validation_errorinvalid_arguments: 'validation_error.invalid_arguments',
  passenger_input_error: 'passenger_input_error',
  passenger_input_errorinvalid_date_of_birth: 'passenger_input_error.invalid_date_of_birth',
  passenger_input_errorinvalid_first_name: 'passenger_input_error.invalid_first_name',
  passenger_input_errorinvalid_gender: 'passenger_input_error.invalid_gender',
  passenger_input_errorinvalid_last_name: 'passenger_input_error.invalid_last_name',
  passenger_input_errorinvalid_passport_country: 'passenger_input_error.invalid_passport_country',
  passenger_input_errorinvalid_passport_number: 'passenger_input_error.invalid_passport_number',
  passenger_input_errorinvalid_passport_expiration: 'passenger_input_error.invalid_passport_expiration',
  passenger_input_errorinvalid_title: 'passenger_input_error.invalid_title',
  passenger_input_errorinvalid_frequent_flyer_numbers: 'passenger_input_error.invalid_frequent_flyer_numbers',
  availability_errorcabin_bag_not_available: 'availability_error.cabin_bag_not_available',
  booking_errorduplicate_names: 'booking_error.duplicate_names',
} as const;

/**
 * An enumeration.
 */
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorCode = {
  server_errorbooking_is_booked_error: 'server_error.booking_is_booked_error',
  client_errorbooking_not_found: 'client_error.booking_not_found',
  server_errorbotrun_failed: 'server_error.botrun_failed',
  server_errorrequest_failedcapabilities: 'server_error.request_failed.capabilities',
  client_errorcard_type_error: 'client_error.card_type_error',
  client_errorexperiment_id_not_found: 'client_error.experiment_id_not_found',
  server_errorfailed_to_create_offer: 'server_error.failed_to_create_offer',
  server_errorfailed_to_save_offer: 'server_error.failed_to_save_offer',
  system_errorflight_not_in_index: 'system_error.flight_not_in_index',
  client_errorillegal_metabooking_action: 'client_error.illegal_metabooking_action',
  client_errorinvalid_consumer_id: 'client_error.invalid_consumer_id',
  client_errorinvalid_input: 'client_error.invalid_input',
  client_errorinvalid_input_for_metabooking_update: 'client_error.invalid_input_for_metabooking_update',
  client_errorinvalid_leg_data: 'client_error.invalid_leg_data',
  client_errorinvalid_leg_string: 'client_error.invalid_leg_string',
  client_errorinvalid_period_data: 'client_error.invalid_period_data',
  client_errorinvalid_secret: 'client_error.invalid_secret',
  client_errorinvalid_service_quantity: 'client_error.invalid_service_quantity',
  server_errormetabookoing_does_not_pass_partner_validation: 'server_error.metabookoing_does_not_pass_partner_validation',
  server_errormetabooking_does_not_support_payment_proxy: 'server_error.metabooking_does_not_support_payment_proxy',
  client_errormetabooking_not_found: 'client_error.metabooking_not_found',
  client_errormetabooking_not_in_booked_state: 'client_error.metabooking_not_in_booked_state',
  server_errormetabooking_is_locked: 'server_error.metabooking_is_locked',
  server_errormissing_payment_info: 'server_error.missing_payment_info',
  client_erroroffer_billing_address_not_found: 'client_error.offer_billing_address_not_found',
  client_errorbilling_address_province_required: 'client_error.billing_address_province_required',
  client_erroroffer_bundle_not_found: 'client_error.offer_bundle_not_found',
  client_erroroffer_bundles_not_found: 'client_error.offer_bundles_not_found',
  client_erroroffer_contact_information_not_found: 'client_error.offer_contact_information_not_found',
  client_erroroffer_not_found: 'client_error.offer_not_found',
  client_erroroffer_passenger_not_found: 'client_error.offer_passenger_not_found',
  client_erroroffer_passengers_not_found: 'client_error.offer_passengers_not_found',
  client_erroroffer_seatmaps_not_found: 'client_error.offer_seatmaps_not_found',
  client_erroroffer_services_not_found: 'client_error.offer_services_not_found',
  client_errororder_successfully_booked: 'client_error.order_successfully_booked',
  client_errororder_passenger_not_found: 'client_error.order_passenger_not_found',
  client_errorpax_types_do_not_match: 'client_error.pax_types_do_not_match',
  server_errorrequest_failedpost_order: 'server_error.request_failed.post_order',
  client_errorservice_not_found: 'client_error.service_not_found',
  server_errorunable_to_get_tokenized_card_from_payment_proxy: 'server_error.unable_to_get_tokenized_card_from_payment_proxy',
  server_errorunexpected_error: 'server_error.unexpected_error',
  server_errorunknown_tokenized_card_failure: 'server_error.unknown_tokenized_card_failure',
  client_errorvendor_not_found: 'client_error.vendor_not_found',
} as const;

export interface Error {
  error_code: HierarchicalErrorCode;
  is_recoverable: boolean;
}

export type DohopServiceBasePrice = PriceWithBreakdown | Price;

export type DohopServiceBaseServiceClass = typeof DohopServiceBaseServiceClass[keyof typeof DohopServiceBaseServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DohopServiceBaseServiceClass = {
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
} as const;

export interface DohopServiceBase {
  service_id: string;
  service_class: DohopServiceBaseServiceClass;
  quantity: number;
  price: DohopServiceBasePrice;
}

export type DohopServicePrice = PriceWithBreakdown | Price;

export type DohopServiceServiceClass = typeof DohopServiceServiceClass[keyof typeof DohopServiceServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DohopServiceServiceClass = {
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
} as const;

export interface DohopService {
  can_increment: boolean;
  can_decrement: boolean;
  service_id: string;
  service_class: DohopServiceServiceClass;
  quantity: number;
  price: DohopServicePrice;
}

export interface DohopChargeTransaction {
  charge: Price;
  transaction_type?: string;
}

export interface Dimensions {
  length: number;
  width: number;
  height: number;
}

export type DeeplinkBreakdownItemBookingType = typeof DeeplinkBreakdownItemBookingType[keyof typeof DeeplinkBreakdownItemBookingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeeplinkBreakdownItemBookingType = {
  travel: 'travel',
  service: 'service',
} as const;

export interface DeeplinkBreakdownItem {
  booking_type: DeeplinkBreakdownItemBookingType;
  price: Price;
}

export interface DeeplinkBreakdown {
  breakdown: DeeplinkBreakdownItem[];
  total: Price;
}

export interface ContactInformationRequest {
  first_name: string;
  last_name: string;
  phone: string;
  phone_country_code: string;
  title: string;
  email: string;
}

export interface ContactInformation {
  first_name: string;
  last_name: string;
  phone: string;
  phone_country_code: string;
  title: string;
  email: string;
}

export interface ClientCapabilities {
  three_ds_dict_value?: boolean;
  three_ds_challenge?: boolean;
}

/**
 * We ground the charges per legs which are a part of a single booking
 */
export interface ChargeBreakdownPerLegs {
  legs: Leg[];
  passenger_charge_breakdown: BundleChargeBreakdown[];
}

export interface ChargeBreakdown {
  breakdown: PriceWithPriceCode[];
}

export interface ChangePassengersRequest {
  n_adults?: number;
  /** List of ages separated by "-", for example: 1-2-3 */
  youngsters_ages?: string;
}

export interface CarrierChargesBreakdownResponse {
  charge_breakdown_per_legs: ChargeBreakdownPerLegs[];
  dohop_fee_breakdown: ChargeBreakdown;
}

export interface CarrierChargeTransaction {
  carrier: string;
  charge: Price;
  legs: Leg[];
  transaction_type?: string;
}

export interface Carrier {
  code: string;
  name: string;
}

export interface CardInfo {
  card_type: string;
  card_expiration: string;
  name_on_card: string;
  pci_proxy_transaction_id?: string;
  origin_url: string;
  client_capabilities?: ClientCapabilities;
  pci_proxy_tokenized_details?: TokenizedCardDetails;
}

export interface BundleSummary {
  code: string;
  price: Price;
}

/**
 * A group of bundles for a given journey sector
 */
export interface BundleGroup {
  bundles: OfferBundle[];
  iatas: string[];
  carrier_codes: string[];
  legs: Leg[];
}

export interface BundleChargeBreakdown {
  breakdown: PriceWithPriceCode[];
  passenger_id: string;
}

export interface BrowserInfo {
  accept_header: string;
  browser_agent: string;
  browser_url: string;
  color_depth: number;
  java_enabled: boolean;
  screen_height: number;
  screen_width: number;
  time_zone_offset: number;
}

export type BookedAdditionalLoungeAccessPrice = PriceWithBreakdown | Price;

export type BookedAdditionalLoungeAccessServiceClass = typeof BookedAdditionalLoungeAccessServiceClass[keyof typeof BookedAdditionalLoungeAccessServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookedAdditionalLoungeAccessServiceClass = {
  lounge_access: 'lounge_access',
} as const;

export interface BookedAdditionalLoungeAccess {
  service_id: string;
  service_class: BookedAdditionalLoungeAccessServiceClass;
  quantity: number;
  price: BookedAdditionalLoungeAccessPrice;
  flight_number: string;
  lounge_entry?: string;
  lounge_exit?: string;
  lounge_identifier?: string;
  reference_id: string;
}

export interface BookOfferResponse {
  order_id: string;
}

export interface BookOfferRequest {
  payment_info?: PaymentInfo;
}

export interface BillingAddress {
  address: string;
  city: string;
  country: string;
  postal_code: string;
  province: string;
}

export interface BillingAddressError {
  error_code: HierarchicalErrorCode;
  is_recoverable: boolean;
  billing_address: BillingAddress;
}

export interface AirportInfo {
  /** Departure/Arrival airport IATA three letter code. */
  airport_iata: string;
  airport_name: string;
  city_iata: string;
  city_name: string;
  /** Country code ISO 3166 */
  country_code: string;
  country_name: string;
  transport_type: TransportType;
}

export type AdyenThreeDSProviderAction = { [key: string]: any };

export type AdyenThreeDSChallengeType = typeof AdyenThreeDSChallengeType[keyof typeof AdyenThreeDSChallengeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdyenThreeDSChallengeType = {
  '3ds1': '3ds1',
  '3ds2': '3ds2',
  '3ds2_frictionless': '3ds2_frictionless',
} as const;

export interface AdyenThreeDS {
  challenge_type: AdyenThreeDSChallengeType;
  provider?: string;
  provider_action: AdyenThreeDSProviderAction;
}

export interface ChallengeInfo {
  challenge_id?: number;
  threeDS: AdyenThreeDS;
  target_bookings: string[];
}

export type AdditionalServiceBasePrice = PriceWithBreakdown | Price;

export type AdditionalServiceBaseServiceClass = typeof AdditionalServiceBaseServiceClass[keyof typeof AdditionalServiceBaseServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalServiceBaseServiceClass = {
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  alyzia: 'alyzia',
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
  seat_selection: 'seat_selection',
  lounge_access: 'lounge_access',
} as const;

export interface AdditionalServiceBase {
  service_id: string;
  service_class: AdditionalServiceBaseServiceClass;
  quantity: number;
  price: AdditionalServiceBasePrice;
}

export type AdditionalLuggageGroundHandlingBasePrice = PriceWithBreakdown | Price;

export type AdditionalLuggageGroundHandlingBaseServiceClass = typeof AdditionalLuggageGroundHandlingBaseServiceClass[keyof typeof AdditionalLuggageGroundHandlingBaseServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLuggageGroundHandlingBaseServiceClass = {
  alyzia: 'alyzia',
} as const;

export interface AdditionalLuggageGroundHandlingBase {
  service_id: string;
  service_class: AdditionalLuggageGroundHandlingBaseServiceClass;
  quantity: number;
  price: AdditionalLuggageGroundHandlingBasePrice;
}

export type AdditionalLuggageGroundHandlingPrice = PriceWithBreakdown | Price;

export type AdditionalLuggageGroundHandlingServiceClass = typeof AdditionalLuggageGroundHandlingServiceClass[keyof typeof AdditionalLuggageGroundHandlingServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLuggageGroundHandlingServiceClass = {
  alyzia: 'alyzia',
} as const;

export interface AdditionalLuggageGroundHandling {
  can_increment: boolean;
  can_decrement: boolean;
  service_id: string;
  service_class: AdditionalLuggageGroundHandlingServiceClass;
  quantity: number;
  price: AdditionalLuggageGroundHandlingPrice;
}

export type AdditionalLuggagePrice = PriceWithBreakdown | Price;

export type AdditionalLuggageServiceClass = typeof AdditionalLuggageServiceClass[keyof typeof AdditionalLuggageServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLuggageServiceClass = {
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
} as const;

export interface AdditionalLuggage {
  can_increment: boolean;
  can_decrement: boolean;
  service_id: string;
  service_class: AdditionalLuggageServiceClass;
  quantity: number;
  price: AdditionalLuggagePrice;
  dimensions?: Dimensions;
  passenger_id: string;
  weight?: number;
}

export type AdditionalLoungeAccessPrice = PriceWithBreakdown | Price;

export type AdditionalLoungeAccessServiceClass = typeof AdditionalLoungeAccessServiceClass[keyof typeof AdditionalLoungeAccessServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLoungeAccessServiceClass = {
  lounge_access: 'lounge_access',
} as const;

export interface AdditionalLoungeAccess {
  can_increment: boolean;
  can_decrement: boolean;
  service_id: string;
  service_class: AdditionalLoungeAccessServiceClass;
  quantity: number;
  price: AdditionalLoungeAccessPrice;
  flight_number: string;
  lounge_entry?: string;
  lounge_exit?: string;
  lounge_identifier?: string;
}

export type APIErrorErrorsItem = ResponseError | NotFoundResponseError;

export interface APIError {
  errors: APIErrorErrorsItem[];
}




/**
 * Create offer response.
 * @summary Create offer response.
 */
export const useGetNewHook = () => {
        const getNew = useCustomInstance<OfferResponse>();

        return (
    params: GetNewParams,
 signal?: AbortSignal
) => {
        return getNew(
          {url: `/new`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetNewQueryKey = (params: GetNewParams,) => [`/new`, ...(params ? [params]: [])] as const;
  

    
export const useGetNewQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError = APIError>(params: GetNewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNewQueryKey(params);

  const getNew =  useGetNewHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>> = ({ signal }) => getNew(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetNewQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>>
export type GetNewQueryError = APIError

/**
 * @summary Create offer response.
 */
export const useGetNew = <TData = Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError = APIError>(
 params: GetNewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetNewQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Create offer response.
 * @summary Create offer response.
 */
export const usePostNewHook = () => {
        const postNew = useCustomInstance<OfferResponse>();

        return (
    params: PostNewParams,
 ) => {
        return postNew(
          {url: `/new`, method: 'post',
        params
    },
          );
        }
      }
    


export const usePostNewMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, TError,{params: PostNewParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, TError,{params: PostNewParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postNew =  usePostNewHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, {params: PostNewParams}> = (props) => {
          const {params} = props ?? {};

          return  postNew(params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostNewMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>>
    
    export type PostNewMutationError = APIError

    /**
 * @summary Create offer response.
 */
export const usePostNew = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, TError,{params: PostNewParams}, TContext>, }
) => {
    
      const mutationOptions = usePostNewMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get offer response
 * @summary Get offer response
 */
export const useGetOfferIdHook = () => {
        const getOfferId = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    params: GetOfferIdParams,
 signal?: AbortSignal
) => {
        return getOfferId(
          {url: `/${offerId}`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetOfferIdQueryKey = (offerId: string,
    params: GetOfferIdParams,) => [`/${offerId}`, ...(params ? [params]: [])] as const;
  

    
export const useGetOfferIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError = APIError>(offerId: string,
    params: GetOfferIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdQueryKey(offerId,params);

  const getOfferId =  useGetOfferIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>> = ({ signal }) => getOfferId(offerId,params, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions}}

export type GetOfferIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>>
export type GetOfferIdQueryError = APIError

/**
 * @summary Get offer response
 */
export const useGetOfferId = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError = APIError>(
 offerId: string,
    params: GetOfferIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdQueryOptions(offerId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Select new bundle.
 * @summary Select new bundle.
 */
export const usePatchOfferIdBundlesHook = () => {
        const patchOfferIdBundles = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    params: PatchOfferIdBundlesParams,
 ) => {
        return patchOfferIdBundles(
          {url: `/${offerId}/bundles`, method: 'patch',
        params
    },
          );
        }
      }
    


export const usePatchOfferIdBundlesMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, TError,{offerId: string;params: PatchOfferIdBundlesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, TError,{offerId: string;params: PatchOfferIdBundlesParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdBundles =  usePatchOfferIdBundlesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, {offerId: string;params: PatchOfferIdBundlesParams}> = (props) => {
          const {offerId,params} = props ?? {};

          return  patchOfferIdBundles(offerId,params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdBundlesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>>
    
    export type PatchOfferIdBundlesMutationError = APIError

    /**
 * @summary Select new bundle.
 */
export const usePatchOfferIdBundles = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, TError,{offerId: string;params: PatchOfferIdBundlesParams}, TContext>, }
) => {
    
      const mutationOptions = usePatchOfferIdBundlesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Update selected quantity for a service.
 * @summary Update selected quantity for a service.
 */
export const usePatchOfferIdServicesHook = () => {
        const patchOfferIdServices = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    selectServiceRequest: SelectServiceRequest,
    params: PatchOfferIdServicesParams,
 ) => {
        return patchOfferIdServices(
          {url: `/${offerId}/services`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: selectServiceRequest,
        params
    },
          );
        }
      }
    


export const usePatchOfferIdServicesMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, TError,{offerId: string;data: SelectServiceRequest;params: PatchOfferIdServicesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, TError,{offerId: string;data: SelectServiceRequest;params: PatchOfferIdServicesParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdServices =  usePatchOfferIdServicesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, {offerId: string;data: SelectServiceRequest;params: PatchOfferIdServicesParams}> = (props) => {
          const {offerId,data,params} = props ?? {};

          return  patchOfferIdServices(offerId,data,params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdServicesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>>
    export type PatchOfferIdServicesMutationBody = SelectServiceRequest
    export type PatchOfferIdServicesMutationError = APIError

    /**
 * @summary Update selected quantity for a service.
 */
export const usePatchOfferIdServices = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, TError,{offerId: string;data: SelectServiceRequest;params: PatchOfferIdServicesParams}, TContext>, }
) => {
    
      const mutationOptions = usePatchOfferIdServicesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Update passenger information for selected passengers in offer, does not add or remove passengers
 * @summary Update passenger information for offer
 */
export const usePatchOfferIdPassengersHook = () => {
        const patchOfferIdPassengers = useCustomInstance<OfferPassengersResponse>();

        return (
    offerId: string,
    updatePassengersRequest: UpdatePassengersRequest,
 ) => {
        return patchOfferIdPassengers(
          {url: `/${offerId}/passengers`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updatePassengersRequest
    },
          );
        }
      }
    


export const usePatchOfferIdPassengersMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, TError,{offerId: string;data: UpdatePassengersRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, TError,{offerId: string;data: UpdatePassengersRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdPassengers =  usePatchOfferIdPassengersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, {offerId: string;data: UpdatePassengersRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdPassengers(offerId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdPassengersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>>
    export type PatchOfferIdPassengersMutationBody = UpdatePassengersRequest
    export type PatchOfferIdPassengersMutationError = APIError

    /**
 * @summary Update passenger information for offer
 */
export const usePatchOfferIdPassengers = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, TError,{offerId: string;data: UpdatePassengersRequest}, TContext>, }
) => {
    
      const mutationOptions = usePatchOfferIdPassengersMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Changes the number of passengers in the offer, calling this method will refresh the offer and remove previous selection.
 * @summary Change number of passengers for offer
 */
export const usePostOfferIdPassengersHook = () => {
        const postOfferIdPassengers = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    changePassengersRequest: ChangePassengersRequest,
    params: PostOfferIdPassengersParams,
 ) => {
        return postOfferIdPassengers(
          {url: `/${offerId}/passengers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: changePassengersRequest,
        params
    },
          );
        }
      }
    


export const usePostOfferIdPassengersMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, TError,{offerId: string;data: ChangePassengersRequest;params: PostOfferIdPassengersParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, TError,{offerId: string;data: ChangePassengersRequest;params: PostOfferIdPassengersParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdPassengers =  usePostOfferIdPassengersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, {offerId: string;data: ChangePassengersRequest;params: PostOfferIdPassengersParams}> = (props) => {
          const {offerId,data,params} = props ?? {};

          return  postOfferIdPassengers(offerId,data,params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdPassengersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>>
    export type PostOfferIdPassengersMutationBody = ChangePassengersRequest
    export type PostOfferIdPassengersMutationError = APIError

    /**
 * @summary Change number of passengers for offer
 */
export const usePostOfferIdPassengers = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, TError,{offerId: string;data: ChangePassengersRequest;params: PostOfferIdPassengersParams}, TContext>, }
) => {
    
      const mutationOptions = usePostOfferIdPassengersMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get the contact information for the offer.
 * @summary Get contact information for offer
 */
export const useGetOfferIdContactHook = () => {
        const getOfferIdContact = useCustomInstance<ContactInformation>();

        return (
    offerId: string,
 signal?: AbortSignal
) => {
        return getOfferIdContact(
          {url: `/${offerId}/contact`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetOfferIdContactQueryKey = (offerId: string,) => [`/${offerId}/contact`] as const;
  

    
export const useGetOfferIdContactQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>, TError = APIError>(offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdContactQueryKey(offerId);

  const getOfferIdContact =  useGetOfferIdContactHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>> = ({ signal }) => getOfferIdContact(offerId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions}}

export type GetOfferIdContactQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>>
export type GetOfferIdContactQueryError = APIError

/**
 * @summary Get contact information for offer
 */
export const useGetOfferIdContact = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>, TError = APIError>(
 offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdContactHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdContactQueryOptions(offerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Update the contact information for the offer.
 * @summary Update contact information for offer
 */
export const usePatchOfferIdContactHook = () => {
        const patchOfferIdContact = useCustomInstance<ContactInformation>();

        return (
    offerId: string,
    contactInformationRequest: ContactInformationRequest,
 ) => {
        return patchOfferIdContact(
          {url: `/${offerId}/contact`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: contactInformationRequest
    },
          );
        }
      }
    


export const usePatchOfferIdContactMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, TError,{offerId: string;data: ContactInformationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, TError,{offerId: string;data: ContactInformationRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdContact =  usePatchOfferIdContactHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, {offerId: string;data: ContactInformationRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdContact(offerId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdContactMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>>
    export type PatchOfferIdContactMutationBody = ContactInformationRequest
    export type PatchOfferIdContactMutationError = APIError

    /**
 * @summary Update contact information for offer
 */
export const usePatchOfferIdContact = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, TError,{offerId: string;data: ContactInformationRequest}, TContext>, }
) => {
    
      const mutationOptions = usePatchOfferIdContactMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get the billing address for the offer.
 * @summary Get billing address information for offer
 */
export const useGetOfferIdBillingAddressHook = () => {
        const getOfferIdBillingAddress = useCustomInstance<BillingAddress>();

        return (
    offerId: string,
 signal?: AbortSignal
) => {
        return getOfferIdBillingAddress(
          {url: `/${offerId}/billing_address`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetOfferIdBillingAddressQueryKey = (offerId: string,) => [`/${offerId}/billing_address`] as const;
  

    
export const useGetOfferIdBillingAddressQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>, TError = APIError>(offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdBillingAddressQueryKey(offerId);

  const getOfferIdBillingAddress =  useGetOfferIdBillingAddressHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>> = ({ signal }) => getOfferIdBillingAddress(offerId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions}}

export type GetOfferIdBillingAddressQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>>
export type GetOfferIdBillingAddressQueryError = APIError

/**
 * @summary Get billing address information for offer
 */
export const useGetOfferIdBillingAddress = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>, TError = APIError>(
 offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdBillingAddressHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdBillingAddressQueryOptions(offerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Update the billing address for the offer.
 * @summary Update billing address for offer
 */
export const usePatchOfferIdBillingAddressHook = () => {
        const patchOfferIdBillingAddress = useCustomInstance<BillingAddress>();

        return (
    offerId: string,
    billingAddress: BillingAddress,
 ) => {
        return patchOfferIdBillingAddress(
          {url: `/${offerId}/billing_address`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: billingAddress
    },
          );
        }
      }
    


export const usePatchOfferIdBillingAddressMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, TError,{offerId: string;data: BillingAddress}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, TError,{offerId: string;data: BillingAddress}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdBillingAddress =  usePatchOfferIdBillingAddressHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, {offerId: string;data: BillingAddress}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdBillingAddress(offerId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdBillingAddressMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>>
    export type PatchOfferIdBillingAddressMutationBody = BillingAddress
    export type PatchOfferIdBillingAddressMutationError = APIError

    /**
 * @summary Update billing address for offer
 */
export const usePatchOfferIdBillingAddress = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, TError,{offerId: string;data: BillingAddress}, TContext>, }
) => {
    
      const mutationOptions = usePatchOfferIdBillingAddressMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Poll an order being booked.
 * @summary Poll the order
 */
export const useGetOfferIdPollHook = () => {
        const getOfferIdPoll = useCustomInstance<PollResponse>();

        return (
    offerId: string,
    params: GetOfferIdPollParams,
 signal?: AbortSignal
) => {
        return getOfferIdPoll(
          {url: `/${offerId}/poll`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetOfferIdPollQueryKey = (offerId: string,
    params: GetOfferIdPollParams,) => [`/${offerId}/poll`, ...(params ? [params]: [])] as const;
  

    
export const useGetOfferIdPollQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>, TError = APIError>(offerId: string,
    params: GetOfferIdPollParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdPollQueryKey(offerId,params);

  const getOfferIdPoll =  useGetOfferIdPollHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>> = ({ signal }) => getOfferIdPoll(offerId,params, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions}}

export type GetOfferIdPollQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>>
export type GetOfferIdPollQueryError = APIError

/**
 * @summary Poll the order
 */
export const useGetOfferIdPoll = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>, TError = APIError>(
 offerId: string,
    params: GetOfferIdPollParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPollHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdPollQueryOptions(offerId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * 
            Returns partial breakdown of carrier charges.
            Partial means that it doesn'tt list all the elements,
            but only a subset, sum of which doesn't equal total.
        
 * @summary Get passenger charge breakdown
 */
export const useGetOfferIdChargesHook = () => {
        const getOfferIdCharges = useCustomInstance<CarrierChargesBreakdownResponse>();

        return (
    offerId: string,
    params: GetOfferIdChargesParams,
 signal?: AbortSignal
) => {
        return getOfferIdCharges(
          {url: `/${offerId}/charges`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetOfferIdChargesQueryKey = (offerId: string,
    params: GetOfferIdChargesParams,) => [`/${offerId}/charges`, ...(params ? [params]: [])] as const;
  

    
export const useGetOfferIdChargesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError = APIError>(offerId: string,
    params: GetOfferIdChargesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdChargesQueryKey(offerId,params);

  const getOfferIdCharges =  useGetOfferIdChargesHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>> = ({ signal }) => getOfferIdCharges(offerId,params, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions}}

export type GetOfferIdChargesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>>
export type GetOfferIdChargesQueryError = APIError

/**
 * @summary Get passenger charge breakdown
 */
export const useGetOfferIdCharges = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError = APIError>(
 offerId: string,
    params: GetOfferIdChargesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdChargesQueryOptions(offerId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Retry book offer as order.
 * @summary Retry book offer
 */
export const usePostOfferIdBookRetryHook = () => {
        const postOfferIdBookRetry = useCustomInstance<BookOfferResponse>();

        return (
    offerId: string,
    retryBookOfferRequest: RetryBookOfferRequest,
 ) => {
        return postOfferIdBookRetry(
          {url: `/${offerId}/book/retry`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: retryBookOfferRequest
    },
          );
        }
      }
    


export const usePostOfferIdBookRetryMutationOptions = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookRetryHook>>>, TError,{offerId: string;data: RetryBookOfferRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookRetryHook>>>, TError,{offerId: string;data: RetryBookOfferRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdBookRetry =  usePostOfferIdBookRetryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookRetryHook>>>, {offerId: string;data: RetryBookOfferRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdBookRetry(offerId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdBookRetryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookRetryHook>>>>
    export type PostOfferIdBookRetryMutationBody = RetryBookOfferRequest
    export type PostOfferIdBookRetryMutationError = ServerPersesModelsErrorResponseErrorPaymentError | APIError

    /**
 * @summary Retry book offer
 */
export const usePostOfferIdBookRetry = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookRetryHook>>>, TError,{offerId: string;data: RetryBookOfferRequest}, TContext>, }
) => {
    
      const mutationOptions = usePostOfferIdBookRetryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Book offer as order.
 * @summary Book offer
 */
export const usePostOfferIdBookHook = () => {
        const postOfferIdBook = useCustomInstance<BookOfferResponse>();

        return (
    offerId: string,
    bookOfferRequest: BookOfferRequest,
    params: PostOfferIdBookParams,
 ) => {
        return postOfferIdBook(
          {url: `/${offerId}/book`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bookOfferRequest,
        params
    },
          );
        }
      }
    


export const usePostOfferIdBookMutationOptions = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, TError,{offerId: string;data: BookOfferRequest;params: PostOfferIdBookParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, TError,{offerId: string;data: BookOfferRequest;params: PostOfferIdBookParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdBook =  usePostOfferIdBookHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, {offerId: string;data: BookOfferRequest;params: PostOfferIdBookParams}> = (props) => {
          const {offerId,data,params} = props ?? {};

          return  postOfferIdBook(offerId,data,params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdBookMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>>
    export type PostOfferIdBookMutationBody = BookOfferRequest
    export type PostOfferIdBookMutationError = ServerPersesModelsErrorResponseErrorPaymentError | APIError

    /**
 * @summary Book offer
 */
export const usePostOfferIdBook = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, TError,{offerId: string;data: BookOfferRequest;params: PostOfferIdBookParams}, TContext>, }
) => {
    
      const mutationOptions = usePostOfferIdBookMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Callback for 3DS
 * @summary Callback for 3DS
 */
export const usePostMbidChallengeCallbackHook = () => {
        const postMbidChallengeCallback = useCustomInstance<ReceivedResponse>();

        return (
    mbid: string,
    notStructured: NotStructured,
    params?: PostMbidChallengeCallbackParams,
 ) => {
        return postMbidChallengeCallback(
          {url: `/${mbid}/challenge_callback`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: notStructured,
        params
    },
          );
        }
      }
    


export const usePostMbidChallengeCallbackMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostMbidChallengeCallbackHook>>>, TError,{mbid: string;data: NotStructured;params?: PostMbidChallengeCallbackParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostMbidChallengeCallbackHook>>>, TError,{mbid: string;data: NotStructured;params?: PostMbidChallengeCallbackParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postMbidChallengeCallback =  usePostMbidChallengeCallbackHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostMbidChallengeCallbackHook>>>, {mbid: string;data: NotStructured;params?: PostMbidChallengeCallbackParams}> = (props) => {
          const {mbid,data,params} = props ?? {};

          return  postMbidChallengeCallback(mbid,data,params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostMbidChallengeCallbackMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostMbidChallengeCallbackHook>>>>
    export type PostMbidChallengeCallbackMutationBody = NotStructured
    export type PostMbidChallengeCallbackMutationError = APIError

    /**
 * @summary Callback for 3DS
 */
export const usePostMbidChallengeCallback = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostMbidChallengeCallbackHook>>>, TError,{mbid: string;data: NotStructured;params?: PostMbidChallengeCallbackParams}, TContext>, }
) => {
    
      const mutationOptions = usePostMbidChallengeCallbackMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Return localized itinerary details for deeplinks version 3.
 */
export const useGetItineraryHook = () => {
        const getItinerary = useCustomInstance<ItineraryWithLegCombo>();

        return (
    params: GetItineraryParams,
 signal?: AbortSignal
) => {
        return getItinerary(
          {url: `/itinerary`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetItineraryQueryKey = (params: GetItineraryParams,) => [`/itinerary`, ...(params ? [params]: [])] as const;
  

    
export const useGetItineraryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError = APIError>(params: GetItineraryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetItineraryQueryKey(params);

  const getItinerary =  useGetItineraryHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>> = ({ signal }) => getItinerary(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetItineraryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>>
export type GetItineraryQueryError = APIError

/**
 * @summary Return localized itinerary details for deeplinks version 3.
 */
export const useGetItinerary = <TData = Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError = APIError>(
 params: GetItineraryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetItineraryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Return charges from the deeplink in a single currency.
 */
export const useGetDeeplinkChargeBreakdownHook = () => {
        const getDeeplinkChargeBreakdown = useCustomInstance<DeeplinkBreakdown>();

        return (
    params: GetDeeplinkChargeBreakdownParams,
 signal?: AbortSignal
) => {
        return getDeeplinkChargeBreakdown(
          {url: `/deeplink/charge-breakdown`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetDeeplinkChargeBreakdownQueryKey = (params: GetDeeplinkChargeBreakdownParams,) => [`/deeplink/charge-breakdown`, ...(params ? [params]: [])] as const;
  

    
export const useGetDeeplinkChargeBreakdownQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError = APIError>(params: GetDeeplinkChargeBreakdownParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeeplinkChargeBreakdownQueryKey(params);

  const getDeeplinkChargeBreakdown =  useGetDeeplinkChargeBreakdownHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>> = ({ signal }) => getDeeplinkChargeBreakdown(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetDeeplinkChargeBreakdownQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>>
export type GetDeeplinkChargeBreakdownQueryError = APIError

/**
 * @summary Return charges from the deeplink in a single currency.
 */
export const useGetDeeplinkChargeBreakdown = <TData = Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError = APIError>(
 params: GetDeeplinkChargeBreakdownParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDeeplinkChargeBreakdownQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get summary information for an order. Last name or order secret needs to be provided
 * @summary Get summary for an order.
 */
export const useGetSummaryHook = () => {
        const getSummary = useCustomInstance<OrderSummaryResponse>();

        return (
    params: GetSummaryParams,
 signal?: AbortSignal
) => {
        return getSummary(
          {url: `/summary`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSummaryQueryKey = (params: GetSummaryParams,) => [`/summary`, ...(params ? [params]: [])] as const;
  

    
export const useGetSummaryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError = APIError>(params: GetSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSummaryQueryKey(params);

  const getSummary =  useGetSummaryHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>> = ({ signal }) => getSummary(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSummaryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>>
export type GetSummaryQueryError = APIError

/**
 * @summary Get summary for an order.
 */
export const useGetSummary = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError = APIError>(
 params: GetSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSummaryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get seatmap by leg id
 * @summary Get seatmap by leg id
 */
export const useGetOfferIdSeatmapLegIdHook = () => {
        const getOfferIdSeatmapLegId = useCustomInstance<OfferSeatMap>();

        return (
    offerId: string,
    legId: string,
    params: GetOfferIdSeatmapLegIdParams,
 signal?: AbortSignal
) => {
        return getOfferIdSeatmapLegId(
          {url: `/${offerId}/seatmap/${legId}`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetOfferIdSeatmapLegIdQueryKey = (offerId: string,
    legId: string,
    params: GetOfferIdSeatmapLegIdParams,) => [`/${offerId}/seatmap/${legId}`, ...(params ? [params]: [])] as const;
  

    
export const useGetOfferIdSeatmapLegIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError = APIError>(offerId: string,
    legId: string,
    params: GetOfferIdSeatmapLegIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdSeatmapLegIdQueryKey(offerId,legId,params);

  const getOfferIdSeatmapLegId =  useGetOfferIdSeatmapLegIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>> = ({ signal }) => getOfferIdSeatmapLegId(offerId,legId,params, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(offerId && legId), ...queryOptions}}

export type GetOfferIdSeatmapLegIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>>
export type GetOfferIdSeatmapLegIdQueryError = APIError

/**
 * @summary Get seatmap by leg id
 */
export const useGetOfferIdSeatmapLegId = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError = APIError>(
 offerId: string,
    legId: string,
    params: GetOfferIdSeatmapLegIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdSeatmapLegIdQueryOptions(offerId,legId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Select a seat.
 * @summary Select a seat.
 */
export const usePostOfferIdSeatsHook = () => {
        const postOfferIdSeats = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    selectSeatRequest: SelectSeatRequest,
    params: PostOfferIdSeatsParams,
 ) => {
        return postOfferIdSeats(
          {url: `/${offerId}/seats`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: selectSeatRequest,
        params
    },
          );
        }
      }
    


export const usePostOfferIdSeatsMutationOptions = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, TError,{offerId: string;data: SelectSeatRequest;params: PostOfferIdSeatsParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, TError,{offerId: string;data: SelectSeatRequest;params: PostOfferIdSeatsParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdSeats =  usePostOfferIdSeatsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, {offerId: string;data: SelectSeatRequest;params: PostOfferIdSeatsParams}> = (props) => {
          const {offerId,data,params} = props ?? {};

          return  postOfferIdSeats(offerId,data,params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdSeatsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>>
    export type PostOfferIdSeatsMutationBody = SelectSeatRequest
    export type PostOfferIdSeatsMutationError = APIError

    /**
 * @summary Select a seat.
 */
export const usePostOfferIdSeats = <TError = APIError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, TError,{offerId: string;data: SelectSeatRequest;params: PostOfferIdSeatsParams}, TContext>, }
) => {
    
      const mutationOptions = usePostOfferIdSeatsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
