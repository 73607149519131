import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeBorder } from '@ui-v2/types/borders';

export const buildBorderVariants = (theme: ThemeV4Fragment): ThemeBorder => ({
  critical: `1px solid ${theme.colours.borderCritical}`,
  default: `1px solid ${theme.colours.borderDefault}`,
  information: `1px solid ${theme.colours.borderInformation}`,
  interactive: `1px solid ${theme.colours.borderInteractive}`,
  success: `1px solid ${theme.colours.borderSuccess}`,
  warning: `1px solid ${theme.colours.borderWarning}`,
  inverse: `1px solid ${theme.colours.borderInverse}`,
  strong: `1px solid ${theme.colours.borderStrong}`,
  subdued: `1px solid ${theme.colours.borderSubdued}`,
  none: 'none',
});
