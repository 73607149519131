import { DohopConnectQuery, useDohopConnectQuery } from '@codegen/cmsUtils';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '../ConstantContext';
import { useSettings } from '../SettingsContext';

const placeholderData: DohopConnectQuery = {
  dohopConnect: {
    name: 'Dohop Connect',
    supportLinks: [],
    termsLinks: [],
    partialBookingLinks: [],
    exitRowTermsLinks: [],
    logo: {
      url: '',
      id: '',
    },
    iconConfig: null,
    supportEmail: '',
    modalTitle: {
      id: '',
      stringId: '',
      __typename: 'ApplicationStringRecord',
      value: '',
    },
    modalImage: { id: '', url: '' },
    modalBulletpoints: [],
  },
};

const useDohopConnect = () => {
  const { locale } = useConstants();
  const { residency } = useSettings();
  const { data } = useDohopConnectQuery(
    { locale },
    { keepPreviousData: true, placeholderData },
  );

  if (!data?.dohopConnect) {
    throw new Error('Failed to fetch dohop connect information');
  }

  const defaultSupportLink = `https://support.dohop.com/hc/${locale}`;

  return {
    name: data.dohopConnect.localizedName?.value || data.dohopConnect.name,
    supportLink:
      getVendorLink({
        links: data.dohopConnect.supportLinks,
        locale,
        residency,
      })?.link || defaultSupportLink,
    termsLink: getVendorLink({
      links: data.dohopConnect.termsLinks,
      locale,
      residency,
    }),
    partialBookingLink:
      getVendorLink({
        links: data.dohopConnect.partialBookingLinks,
        locale,
        residency,
      })?.link || defaultSupportLink,
    logo: data.dohopConnect.logo,
    dohopIconConfig: data.dohopConnect.iconConfig,
    exitRowTermsLink:
      getVendorLink({
        links: data.dohopConnect.exitRowTermsLinks,
        locale,
        residency,
      })?.link || defaultSupportLink,
    supportEmail: data.dohopConnect.supportEmail,
    modalTitle: data.dohopConnect.modalTitle.value,
    modalImage: data.dohopConnect.modalImage,
    modalBulletpoints: data.dohopConnect.modalBulletpoints,
  };
};

export default useDohopConnect;
