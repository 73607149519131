import { css, Theme } from '@emotion/react';
import { createTypography } from './base';

export const getDefaults = ({ colours, typography }: Theme) => css`
  body {
    color: ${colours.text.default};
    ${createTypography(typography.body01)};
  }

  button:active,
  div[role='button']:active,
  input:active {
    outline: none;
  }
`;
