import useTranslation from 'next-translate/useTranslation';
import { ContentFragment } from '@codegen/cmsUtils';
import { Language } from '@shared/types/enums';
import DefaultErrorBoundary from '../ErrorBoundary/DefaultErrorBoundary';
import LoadingShimmer from '../LoadingShimmer/LoadingShimmer';
import PartnerModal from '../Modal/PartnerModal/PartnerModal';
import StandaloneContent from './StandaloneContent';

interface Props {
  className?: string;
  content?: ContentFragment[];
  height?: string;
  id: string;
  locale: Language;
  residency: string;
  title?: string;
  toggleModal: () => void;
}

const StandaloneContentModal = ({
  className,
  content,
  height,
  id,
  locale,
  residency,
  title,
  toggleModal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <PartnerModal
      className={className}
      height={height ?? 'auto'}
      id={id}
      onCloseModal={toggleModal}
      title={title}
    >
      <DefaultErrorBoundary
        text={t('Something went wrong when fetching this content')}
      >
        {content ? (
          <StandaloneContent
            content={content}
            locale={locale}
            residency={residency}
          />
        ) : (
          <LoadingShimmer />
        )}
      </DefaultErrorBoundary>
    </PartnerModal>
  );
};

export default StandaloneContentModal;
