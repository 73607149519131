import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mqMin } from '../../../styles/base';

export interface Props {
  children?: ReactNode;
  className?: string;
}

const ButtonContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    width: auto;
    flex-direction: column;
    padding: ${spacings['24']}px;
    gap: ${spacings['4']}px;

    ${mqMin.Small} {
      flex-direction: row;
      gap: ${spacings['4']}px;
    }
  `,
);

const PartnerModalFooter = ({ children, className }: Props) => {
  return <ButtonContainer className={className}>{children}</ButtonContainer>;
};

export default PartnerModalFooter;
