import * as Sentry from '@sentry/nextjs';
import { RUNTIME_ENV } from '@shared/types/enums';

export const CURRENT_DATOCMS_ENV =
  process.env['NEXT_PUBLIC_DATO_CMS_ENVIRONMENT'];

export const GATEWAY_URL =
  process.env['NEXT_PUBLIC_GATEWAY_URL'] ||
  'https://gateway.staging.dohop.net/api/graphql';

export const gatewayFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers = {} as RequestInit['headers'],
): (() => Promise<TData>) => {
  const queryString = `query=${query}&variables=${JSON.stringify(variables)}`;

  return async (): Promise<TData> => {
    const res = await fetch(`${GATEWAY_URL}?${queryString}`, {
      method: 'GET',
      ...{ headers: { 'Content-Type': 'application/json', ...headers } },
    });

    const json = await res.json();

    if (json.errors) {
      const [{ message }] = json.errors;

      throw new Error(message);
    }

    return json.data;
  };
};

export const silentErrorLogger = (error: unknown) =>
  Sentry.captureException(error);

export const defaultReactQueryClientOptions = {
  queries: {
    staleTime: Infinity,
    onError: silentErrorLogger,
  },
};

export const cmsFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers = {} as RequestInit['headers'],
): (() => Promise<TData>) => {
  const url = 'https://graphql.datocms.com';

  return async (): Promise<TData> => {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer c9db0efa630a94b16531257c1dfb4a',
        'X-Exclude-Invalid': 'true',
        ...(CURRENT_DATOCMS_ENV && { 'X-ENVIRONMENT': CURRENT_DATOCMS_ENV }),
        ...(process.env['NEXT_PUBLIC_RUNTIME_ENV'] !== RUNTIME_ENV.production
          ? { 'X-Include-Drafts': 'true' }
          : {}),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const [{ message }] = json.errors;

      throw new Error(message);
    }

    return json.data;
  };
};

export const parseQueryString = (query?: string | string[] | null) => {
  if (!query) {
    return '';
  }

  if (typeof query === 'string' && query !== 'UNDEFINED') {
    return query;
  }

  return query[0] ?? '';
};
