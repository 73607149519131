import { css } from '@emotion/react';

const transaviaFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Nexa Regular';
    src: url('/fonts/Transavia/3A5BD2_0_0.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Nexa Bold';
    src: url('/fonts/Transavia/nexa-bold.otf');
  }
`;

export default transaviaFonts;
