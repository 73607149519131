import { useGetBookingConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '../ConstantContext';
import { useExperiments } from '../ExperimentContext';

const useBookingConfig = () => {
  const { locale, partner } = useConstants();

  const { data } = useGetBookingConfigQuery({ partner, locale });
  const { experimentData } = useExperiments();
  const bookingConfig =
    experimentData?.bookingConfig ?? data?.partner?.partnerBookingConfig;

  const steps = bookingConfig?.bookingSteps ?? [];

  const showBasketIcon = bookingConfig?.showBasketIcon ?? true;
  const hideTaxBreakdown = bookingConfig?.hideTaxBreakdown ?? false;
  const showSherpaOnConfirmationPage =
    bookingConfig?.showSherpaOnConfirmationPage ?? false;

  const countryBlocklist =
    data?.partner?.partnerBookingConfig?.countryBlocklist ?? [];

  return {
    steps,
    showBasketIcon,
    showSherpaOnConfirmationPage,
    hideTaxBreakdown,
    countryBlocklist,
  };
};

export default useBookingConfig;
