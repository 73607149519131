/* eslint-disable functional/immutable-data */
import { useRef, useEffect, memo } from 'react';
import { createPortal } from 'react-dom';

export interface Props {
  automaticRemovalOnUnmount?: boolean;
  beforeElementId?: string;
  children: React.ReactNode;
  className?: string;
  id: string;
}

const Portal = ({
  automaticRemovalOnUnmount = true,
  beforeElementId,
  children,
  className,
  id,
}: Props) => {
  const el = useRef(
    document.getElementById(id) || document.createElement('div'),
  );

  const beforeElement =
    beforeElementId && document.getElementById(beforeElementId);

  useEffect(() => {
    const { current } = el;

    if (!current.parentElement) {
      current.id = id;

      if (className) {
        current.className = className;
      }

      if (beforeElement) {
        document.body.insertBefore(current, beforeElement);
      } else {
        document.body.appendChild(current);
      }
    }

    return () => {
      if (current.parentElement && automaticRemovalOnUnmount) {
        current.parentElement.removeChild(current);
      }
    };
  }, [id, className, beforeElement, automaticRemovalOnUnmount]);

  return createPortal(children, el.current);
};

export default memo(Portal);
