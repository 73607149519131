import 'intl-pluralrules';
import 'focus-visible';
import 'resize-observer-polyfill';
import { useMemo } from 'react';
import { AppProps } from 'next/app';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Partner } from '@shared/types/enums';
import GridOverlay from '@ui/components/Grid/GridOverlay';
import { defaultReactQueryClientOptions } from '@utils/queryUtils';
import HeadTags from '@web/components/HeadTags';
import PageLayout from '@web/components/PageLayout';
import { AnalyticsProvider } from '@web/context/AnalyticsContext';
import ApplicationStringProvider from '@web/context/ApplicationStringProvider';
import { ConstantProvider } from '@web/context/ConstantContext';
import { ErrorOrchestratorContextProvider } from '@web/context/ErrorOrchestratorContext';
import ExperimentProvider from '@web/context/ExperimentContext';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { SettingsProvider } from '@web/context/SettingsContext';
import { StepProvider } from '@web/context/StepContext';
import { ThemeProvider } from '@web/context/ThemeContext';
import { Route } from '@web/types/enums';
import { mapHostNameToPartner } from '@web/utils/hostnameUtils';

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const queryClient = useMemo(
    () => new QueryClient({ defaultOptions: defaultReactQueryClientOptions }),
    [],
  );

  const {
    query: {
      partner = pageProps.partner ||
        mapHostNameToPartner(
          typeof window !== 'undefined' ? window.location.hostname : undefined,
        ),
    },
  } = usePartnerRouter();

  const { route } = usePartnerRouter();

  if (route === Route.NotFound) {
    return (
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} queryClient={queryClient} />
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ConstantProvider partner={partner as Partner}>
          <SettingsProvider>
            <main className="app">
              <ExperimentProvider>
                <AnalyticsProvider>
                  <ApplicationStringProvider>
                    <ThemeProvider>
                      <StepProvider>
                        <ErrorOrchestratorContextProvider>
                          <HeadTags />
                          <PageLayout>
                            <Component {...pageProps} />
                            <GridOverlay />
                          </PageLayout>
                        </ErrorOrchestratorContextProvider>
                      </StepProvider>
                    </ThemeProvider>
                  </ApplicationStringProvider>
                </AnalyticsProvider>
              </ExperimentProvider>

              {process.env.NEXT_PUBLIC_USE_REACT_QUERY_DEVTOOLS && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </main>
          </SettingsProvider>
        </ConstantProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default CustomApp;
