import { ContentFragment } from '@codegen/cmsUtils';
import { Language } from '@shared/types/enums';
import StandaloneContentModal from './StandaloneContentModal';

interface Props {
  className?: string;
  content?: ContentFragment[];
  contentIdentifier: string;
  height?: string;
  locale: Language;
  residency: string;
  title?: string;
  toggleModal: () => void;
}

const StandaloneContentModalContainer = ({
  className,
  content,
  contentIdentifier,
  height,
  locale,
  residency,
  title,
  toggleModal,
}: Props) => {
  return (
    <StandaloneContentModal
      className={className}
      content={content}
      height={height}
      id={contentIdentifier}
      locale={locale}
      residency={residency}
      title={title}
      toggleModal={toggleModal}
    />
  );
};

export default StandaloneContentModalContainer;
