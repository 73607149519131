export const parseKeyValueCookieToObject = (
  cookie?: string | null,
  splitOn = ',',
) => {
  if (!cookie) {
    return {};
  }

  return decodeURIComponent(cookie)
    .split(splitOn)
    .reduce<Record<string, string>>((acc, curr) => {
      const [itemKey, itemValue] = curr.split('=');

      if (!itemKey || !itemValue) {
        return acc;
      }

      return { ...acc, [itemKey.trim()]: itemValue.trim() };
    }, {});
};

export const parseKeyValueCookie = (key: string, cookie?: string | null) => {
  const options = parseKeyValueCookieToObject(cookie);

  return options[key];
};

export const addToKeyValueCookie = (
  key: string,
  value: string,
  cookie?: string | null,
) => {
  const obj = { ...parseKeyValueCookieToObject(cookie), [key]: value };

  return Object.keys(obj)
    .map((k) => `${k}=${obj[k]}`)
    .join(',');
};

export const deleteFromKeyValueCookie = (
  key: string,
  cookie?: string | null,
) => {
  const { [key]: value, ...newObj } = parseKeyValueCookieToObject(cookie);

  return [
    Object.keys(newObj)
      .map((k) => `${k}=${newObj[k]}`)
      .join(','),
    value,
  ];
};
