export const GATEWAY_URL =
  process.env['NEXT_PUBLIC_GATEWAY_URL'] ||
  'https://gateway.staging.dohop.app/api/graphql';

export const gatewayFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers = {} as RequestInit['headers'],
): (() => Promise<TData>) => {
  const queryString = `query=${query}&variables=${JSON.stringify(variables)}`;

  return async (): Promise<TData> => {
    const res = await fetch(`${GATEWAY_URL}?${queryString}`, {
      method: 'GET',
      ...{ headers: { 'Content-Type': 'application/json', ...headers } },
    });

    const json = await res.json();

    if (json.errors) {
      const [{ message }] = json.errors;

      throw new Error(message);
    }

    return json.data;
  };
};
