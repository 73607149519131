import { Translate } from 'next-translate';
import { Vendors } from '@codegen/cmsTypes';
import {
  BundleFragment,
  IconConfigFragment,
  ImageWithConfigFragment,
} from '@codegen/cmsUtils';
import { Leg, Summary } from '@codegen/offerAPI';
import { findVendorByIata } from '@ui/utils/vendorUtils';
import { BundleService } from '@utils/sharedServiceUtils';
import { formatLegsToString } from './bookingUtils';
import { constructCombinedServices } from './serviceUtils';

export const getBundleName = ({
  bundleCode,
  bundleConfig,
  legs,
}: {
  bundleCode: string;
  bundleConfig?: BundleFragment[];
  legs?: Leg[];
}) => {
  const selectedBundle = bundleConfig?.find(
    (bundle) => bundle.bundleId === bundleCode,
  );

  const fallbackAirlines = legs?.map(
    (itinerary) => itinerary.marketing_carrier.name,
  );

  const fallbackAirlineFiltered = [...new Set(fallbackAirlines)].join(', ');

  return selectedBundle?.name ?? (fallbackAirlineFiltered || bundleCode);
};

// returns the itinerary as it is displayed in the summary
export const getItineraryItems = (legs: Leg[]) => {
  return {
    leg_id: legs[0]?.leg_id,
    origin: legs[0]?.origin,
    destination: legs[legs.length - 1]?.destination,
    is_outbound: legs[0]?.is_outbound,
    marketing_carrier: legs[0]?.marketing_carrier,
    operating_carrier: legs[0]?.operating_carrier,
    flight_number: legs[0]?.flight_number,
    departure: legs[0]?.departure,
    arrival: legs[legs.length - 1]?.arrival,
  };
};

export const getIatasFromSummary = (summary?: Summary) => {
  if (!summary) {
    return [];
  }

  return summary.leg_summaries.reduce<string[]>((iatas, legSummary) => {
    const legIatas = legSummary.legs
      .reduce<string[]>((legAcc, leg) => {
        if (legAcc.includes(leg.marketing_carrier.code)) {
          return legAcc;
        }

        return [...legAcc, leg.marketing_carrier.code];
      }, [])
      .filter((iata) => !iatas.includes(iata));

    return [...iatas, ...legIatas];
  }, []);
};

export interface BundleInfo {
  code: string;
  includedServices: BundleService[];
  itinerary: ReturnType<typeof getItineraryItems>;
  legItineraryName: string;
  name: string;
}

export const getSelectedBundlesFromSummary = ({
  getFallbackIcon,
  summary,
  t,
  vendors,
}: {
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  summary?: Summary;
  t: Translate;
  vendors: Vendors;
}) => {
  if (!summary) {
    return [];
  }

  const bundleCodes = summary.leg_summaries.reduce<string[]>(
    (acc, leg) =>
      acc.includes(leg.bundle.code) ? acc : [...acc, leg.bundle.code],
    [],
  );

  return bundleCodes
    .map((bundleCode) => {
      const bundleConfig = Object.values(vendors).find(
        (vendor) =>
          vendor.vendorBookingConfig?.bundles.find(
            (bundle) => bundle.bundleId === bundleCode,
          ),
      )?.vendorBookingConfig?.bundles;

      const legSummary = summary.leg_summaries.find(
        (leg) => leg.bundle.code === bundleCode,
      );

      if (legSummary) {
        const itinerary = getItineraryItems(legSummary.legs);

        const cmsServices = findVendorByIata(
          vendors,
          itinerary.marketing_carrier?.code,
        )?.vendorBookingConfig?.servicesConfig?.services;

        const includedServices = constructCombinedServices({
          offerServices: legSummary.included_services,
          cmsServices: cmsServices || [],
          t,
          getFallbackIcon,
        });

        const legItineraryName = formatLegsToString(legSummary.legs);

        return {
          code: bundleCode,
          name: getBundleName({
            bundleCode,
            bundleConfig,
            legs: legSummary.legs,
          }),
          legItineraryName,
          includedServices,
          itinerary,
        };
      }

      return null;
    })
    .filter((item): item is BundleInfo => Boolean(item));
};
