import { ReactElement } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { clampLines, createTypography } from '../../styles/base';

export interface Props {
  className?: string;
  leftContent?: ReactElement | null | boolean;
  rightContent?: ReactElement;
  title?: string | ReactElement;
}

const Container = styled.div`
  position: relative;
`;

export const NavigationContainer = styled.div(
  ({ theme: { colours, spacings } }) => [
    css`
      padding: ${spacings['16']}px;
      background: ${colours.brand.primary};
      color: ${colours.text.on.interactive.primary.default};
    `,
  ],
);

const LeftContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const RightContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const TitleWrapper = styled.div(({ theme }) => [
  clampLines(1),
  createTypography(theme.typography.heading04),
  css`
    padding-bottom: 2px;
    text-align: center;
  `,
]);

const StepNavigation = ({
  className,
  leftContent = <div />,
  rightContent = <div />,
  title,
}: Props) => (
  <Container className={className}>
    <NavigationContainer>
      <LeftContentWrapper>{leftContent}</LeftContentWrapper>
      <TitleWrapper title={typeof title === 'string' ? title : undefined}>
        {title}
      </TitleWrapper>
      <RightContentWrapper>{rightContent}</RightContentWrapper>
    </NavigationContainer>
  </Container>
);

export default StepNavigation;
